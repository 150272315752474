/* Base styles for mobile and smaller screens */
.coaching-content {
  margin-top: 10px; /* Space below the header */
  text-align: left; /* Left-align text on small screens */
  padding: 0 10px; /* Padding on the sides */
}

.coaching-content h1 {
  font-size: 2.5rem; /* Responsive font size for mobile */
  margin-bottom: 20px; /* Space below the h1 header */
}

.coaching-content h2 {
  font-size: 2rem; /* Responsive font size for subheaders */
  margin-top: 10px;
  margin-bottom: 10px;
}

.coaching-content ul {
  padding-left: 20px; /* Space for bullet points */
}

.coaching-content li {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.4rem; /* Text will wrap and not expand indefinitely */
}

/* Styles for wider screens */
@media (min-width: 768px) {

  .coaching-content {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start (left) */
    margin: 10px 10px 10px 10px;
  }

  .coaching-content h1 {
    font-size: 44px; /* Larger font size for wider screens */
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .coaching-content h2 {
    font-size: 36px; /* Larger font size for wider screens */
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .coaching-content ul {
    padding-left: 25px; /* Adjust padding for bullet points */
  }

  .coaching-content li {
    font-size: 22px; /* Larger font size for readability */
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 800px; /* Limit the width of list items for readability */
  }
}