.hero-section {
  background-image: url('../public/mtn_photo.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  height: auto; /* Adjust to 'auto' to accommodate content */
  min-height: 60vh; /* Use 'min-height' to ensure it covers at least 60% of the viewport height */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 1350px;
  margin: 0 auto; /* Center the hero-section itself if it's not full width */
}

.overlay-text {
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 20px;
  border-radius: 10px;
  margin-top: 100px;
}

.overlay-text h1 {
  font-size: 52px; /* Larger font size for the heading */
  color: white; /* Optional: specify text color */
  margin-bottom: 0.5rem; /* Adjust bottom margin */
}

.overlay-text p {
  font-size: 26px; /* Larger font size for the paragraph */
  color: white; /* Optional: specify text color */
  margin-bottom: 1rem; /* Adjust bottom margin */
  Font-weight: bold;
}

.consultation-box {
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background, similar to overlay-text */
  padding: 20px;
  border-radius: 10px; /* Matches the overlay-text border radius */
  margin-top: 50px; /* Space between the overlay-text and the consultation-box */
  width: auto; /* Adjust width as needed */
  max-width: 80%; /* Ensures the box is not too wide on larger screens */
}

.consultation-link {
  color: white; /* Matches the text color of overlay-text */
  font-size: 26px; /* Matches the paragraph font size of overlay-text */
  font-weight: bold; /* Ensures the text stands out */
  text-decoration: none; /* Removes underline from the link */
  display: inline-block; /* Allows padding to be applied */
  padding: 10px 20px; /* Adds some padding around the text for better visibility */
  border-radius: 5px; /* Optional: adds a slight border radius for a button-like appearance */
  background-color: rgba(255, 255, 255, 0.2); /* Optional: slightly lighter background for the link */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.consultation-link:hover {
  background-color: rgba(255, 255, 255, 0.4); /* Lightens the background on hover */
}