.App-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure full width */
  background-color: white;
  padding: 20px; /* Add padding for spacing inside the footer */
}


/* New Styles for Social Links and Subscription Form Container */
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.social-links {
  display: flex;
  gap: 20px; /* Adjust the space between the links as needed */
  justify-content: center; /* Center the links if there's extra space */
}

.social-link {
  color: #000; /* Adjust the color as needed */
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
  /* You may want to add background-color, font-size, etc. */
}

/* For responsiveness, consider adjusting layout on smaller screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .social-links {
    margin-top: 20px;
  }
}
